import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Startseite | Sportschule - Entwicklungs- und Sportmöglichkeiten für junge Sportler
			</title>
			<meta name={"description"} content={"Willkommen in der Sportschule! Wählen Sie unsere Schule für die Entwicklung des sportlichen Nachwuchses. Wir bieten die besten Bedingungen für die sportliche Entwicklung, spezielle Angebote und eine sportliche Infrastruktur. Kommen Sie noch heute zu uns!"} />
			<meta property={"og:title"} content={"Startseite | Sportschule - Entwicklungs- und Sportmöglichkeiten für junge Sportler"} />
			<meta property={"og:description"} content={"Willkommen in der Sportschule! Wählen Sie unsere Schule für die Entwicklung des sportlichen Nachwuchses. Wir bieten die besten Bedingungen für die sportliche Entwicklung, spezielle Angebote und eine sportliche Infrastruktur. Kommen Sie noch heute zu uns!"} />
			<meta property={"og:image"} content={"https://cubomuy.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cubomuy.com/img/icon-man.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cubomuy.com/img/icon-man.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cubomuy.com/img/icon-man.png"} />
			<meta name={"msapplication-TileColor"} content={"https://cubomuy.com/img/icon-man.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="112px 0 0px 0" background="--color-green" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Sportschule
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Willkommen in unserer Sportschule!
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/kontakt"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Melden Sie sich für einen Besuch an
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://cubomuy.com/img/banner.png"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9" background="--color-green">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						FÜR NEUE SCHÜLER
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--dark" font="--lead" lg-text-align="center">
					Wählen Sie die beste Sportschule. Wir sorgen dafür, dass
neuen Kunden die besten Bedingungen für ihre Entwicklung und ihr sportliches Wachstum zu bieten.
Holen Sie sich spezielle Angebote, die Ihnen helfen, Ihr sportliches Potenzial zu entfalten.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://cubomuy.com/img/1.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section background="--color-green" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Team
						</Text>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
							Unser Auftrag
						</Text>
						<Text as="p" margin="12px 0" font="--lead">
							Unser Hauptziel ist es, die junge Generation durch Sport zu einem gesunden Lebensstil zu inspirieren. Wir glauben, dass Sport nicht nur einen starken Körper formt, sondern auch den Charakter entwickelt und hilft, große Ziele im Leben zu erreichen.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://cubomuy.com/img/2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://cubomuy.com/img/3.jpeg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://cubomuy.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});